body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 2rem;
}

th {
  text-align: left;
}

tr.table-primary > th, tr.table-info > th, tr.table-warning > th, tr.table-danger > th {
  cursor: help;
}